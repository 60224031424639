<template>
  <BaseTable
    ref="capabilities-table"
    :columns="columns"
    :resource="$data.$constants.RESOURCES.RESOURCE_CAPABILITIES"
    :filters="filters"
    :loading="loading"
    disable-pagination
    hide-toolbar
  >
    <template #cell(view)="data">
      <b-form-checkbox
        v-model="data.item.view"
        :value="1"
        :unchecked-value="0"
        @change="handleUpdateCapabilityRow(data.item)"
      />
    </template>
    <template #cell(all)="data">
      <b-form-checkbox
        v-model="data.item.all"
        :value="1"
        :unchecked-value="0"
        @change="handleUpdateCapabilityRow(data.item)"
      />
    </template>
    <template #cell(actions)="data">
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_CONFIGURATION,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
      >
        <span
          class="d-inline-block px-50 text-danger"
          @click="handleDeleteButtonClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </span>
      </b-link>
    </template>
  </BaseTable>
</template>

<script>
import CapabilitiesApi from '@/api/capabilities-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'

export default {
  name: 'RoleCapabilitiesTable',
  components: { BaseTable },
  props: {
    roleId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'RECURSO',
          key: 'resource',
          sortable: false,
        },
        {
          label: 'SOLO LECTURA',
          key: 'view',
          sortable: false,
        },
        {
          label: 'LECTURA/ESCRITURA',
          key: 'all',
          sortable: false,
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    filters() {
      return { role_id: this.roleId || null }
    },
  },
  methods: {
    loadRoleCapabilities() {
      this.$refs['capabilities-table'].loadData()
    },
    async handleUpdateCapabilityRow(capability) {
      this.loading = true
      try {
        await CapabilitiesApi.edit(capability.id, capability)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteButtonClick(capability) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await CapabilitiesApi.delete(capability.id)
        await this.loadRoleCapabilities()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
