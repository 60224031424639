<template>
  <div>
    <BaseCard
      title="datos del rol"
      :loading="loading"
      :footer-visible="false"
      actions-visible
      :edit-action-visible="false"
      :resource="$data.$constants.RESOURCES.RESOURCE_CONFIGURATION"
      @delete-confirmation="handleDeleteConfirmation"
    >
      <RoleProfileForm
        ref="role-profile-form"
        v-model="role"
      />
      <b-row
        class="pb-2 px-2"
        align-v="center"
        align-h="end"
      >
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2"
            :disabled="loading"
            @click="handleSaveButtonClick"
          >
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </BaseCard>
    <RoleCapabilitiesCard :role-id="roleId" />
  </div>
</template>

<script>
import ApiRestService from '@/api/base-api'
import RoleCapabilitiesCard from '@/components/role/card/RoleCapabilitiesCard.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import RoleProfileForm from '@/components/role/form/RoleProfileForm.vue'

export default {
  components: { RoleProfileForm, BaseCard, RoleCapabilitiesCard },
  data() {
    return {
      loading: false,
      role: null,
      roleId: this.$route.params.id.toString(),
    }
  },
  mounted() {
    this.loadRole()
  },
  methods: {
    async loadRole() {
      this.loading = true
      try {
        const response = await ApiRestService.get(this.roleId, this.$data.$constants.RESOURCES.RESOURCE_ROLES)
        this.role = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.roleProfileForm.validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        delete this.role.capabilities
        await ApiRestService.edit(this.$route.params.id, this.role, this.$data.$constants.RESOURCES.RESOURCE_ROLES)
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteConfirmation() {
      this.loading = true
      try {
        await ApiRestService.delete(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_ROLES)
        await this.$router.push({ name: 'roles' })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
