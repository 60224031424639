import { BaseApi } from '@/api/base-api'
import { RESOURCE_CAPABILITIES } from '@/shared/constants/resources'
import Vue from 'vue'

class CapabilitiesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  getResourceGroups() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get(`/${this.resource}/resource-groups`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso. Por favor inténtelo de nuevo mas tarde.')
        console.error(error)
        reject(error)
      }
    })
  }
}

export default new CapabilitiesApi(RESOURCE_CAPABILITIES)
