<template>
  <BaseCard
    title="Capabilities del rol"
    collapsable
    :footer-visible="false"
    actions-visible
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir capability</u>
      </b-link>
    </template>
    <RoleCapabilitiesTable
      ref="role-capabilities-table"
      :role-id="roleId"
    />
    <RoleCapabilityCreationModal
      v-model="creationModalVisible"
      :role-id="roleId"
      @capability-created="$refs['role-capabilities-table'].loadRoleCapabilities()"
    />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import RoleCapabilitiesTable from '@/components/role/table/RoleCapabilitiesTable.vue'
import RoleCapabilityCreationModal from '@/components/role/modal/RoleCapabilityCreationModal.vue'

export default {
  name: 'RoleCapabilitiesCard',
  components: { RoleCapabilityCreationModal, RoleCapabilitiesTable, BaseCard },
  props: {
    roleId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      creationModalVisible: false,
    }
  },
}
</script>

<style scoped>

</style>
