<template>
  <validation-observer ref="role-profile-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col lg="6">
          <validation-provider
            v-slot="validationContext"
            name="Nombre único"
            rules="required"
          >
            <b-form-group
              label="Nombre único"
              label-for="role-name"
            >
              <b-form-input
                id="role-name"
                :value="model.name"
                placeholder="Nombre único"
                :state="$getValidationState(validationContext)"
                @input="update('name', $event)"
              />
            </b-form-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col lg="6">
          <validation-provider
            v-slot="validationContext"
            name="Descripción"
            rules="required"
          >
            <b-form-group
              label="Descripción"
              label-for="role-display-name"
            >
              <b-form-input
                id="role-display-name"
                :value="model.display_name"
                placeholder="Descripción"
                :state="$getValidationState(validationContext)"
                @input="update('display_name', $event)"
              />
            </b-form-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'

export default {
  name: 'RoleProfileForm',
  mixins: [FormMixin],
  methods: {
    validateForm() {
      return this.$refs['role-profile-form'].validate()
    },
  },
}
</script>

<style scoped>

</style>
