<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <h1 class="font-large-1 text-center pt-2">
      Añadir capability
    </h1>
    <validation-observer ref="role-capability-form">
      <b-form
        class="py-2"
        @submit.prevent
      >
        <b-row align-h="center">
          <b-col
            sm="12"
            lg="10"
          >
            <validation-provider
              v-slot="validationContext"
              name="Recurso"
              rules="required"
            >
              <b-form-group
                label="Recurso"
                label-for="capability-resource"
              >
                <BaseSelect
                  id="capability-resource"
                  v-model="formModel.resource"
                  :options="resourceGroups"
                  :reduce="item => item.value"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            sm="12"
            lg="5"
          >
            <b-form-checkbox
              v-model="formModel.all"
              class="my-2"
              :value="1"
              :unchecked-value="0"
            >
              Lectura/Escritura
            </b-form-checkbox>
          </b-col>
          <b-col
            sm="12"
            lg="5"
          >
            <b-form-checkbox
              v-model="formModel.view"
              class="my-2"
              :value="1"
              :unchecked-value="0"
            >
              Solo lectura
            </b-form-checkbox>

          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import CapabilitiesApi from '@/api/capabilities-api'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'RoleCapabilityCreationModal',
  components: { BaseSelect },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    roleId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      resourceGroups: [],
      formModel: {
        resource: null,
        view: 0,
        all: 0,
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.loadResources()
  },
  methods: {
    async loadResources() {
      const response = await CapabilitiesApi.getResourceGroups()
      this.resourceGroups = Object.keys(response.data).map(key => ({ name: key, value: response.data[key] }))
    },
    async handleAcceptButtonClick() {
      const valid = await this.$refs['role-capability-form'].validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        await CapabilitiesApi.create({ role_id: this.roleId, ...this.formModel })
        this.$emit('input', false)
        this.$emit('capability-created')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
