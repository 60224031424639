import { render, staticRenderFns } from "./RoleCapabilityCreationModal.vue?vue&type=template&id=095e9488&scoped=true&"
import script from "./RoleCapabilityCreationModal.vue?vue&type=script&lang=js&"
export * from "./RoleCapabilityCreationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095e9488",
  null
  
)

export default component.exports